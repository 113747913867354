import { jsPDF } from 'jspdf';
import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';

export const exportToPDF = ({ userAddress, destinationAddress, concerneText, introductionText, locationDate, initialSalutation, finalSalutation, signature, reportContent }) => {
  const doc = new jsPDF();

  const addSection = (title, text, yPos) => {
    doc.setFont("helvetica", "bold");
    doc.text(title, 10, yPos);
    doc.setFont("helvetica", "normal");
    doc.text(text, 10, yPos + 10);
    return yPos + 20;
  };

  let yPosition = 20;
  if (userAddress) yPosition = addSection(userAddress, yPosition);
  if (destinationAddress) yPosition = addSection(destinationAddress, yPosition);
  if (concerneText) yPosition = addSection(concerneText, yPosition);
  if (locationDate) yPosition = addSection(locationDate, yPosition);
  if (initialSalutation) yPosition = addSection( initialSalutation, yPosition);
  if (introductionText) yPosition = addSection( introductionText, yPosition);

  // Ajouter le contenu du rapport principal
  if (reportContent) yPosition = addSection(reportContent, yPosition);

  if (finalSalutation) yPosition = addSection(finalSalutation, yPosition);
  if (signature) yPosition = addSection(signature, yPosition);

  doc.save('rapport.pdf');
};

export const exportToWord = async ({ userAddress, destinationAddress, concerneText, introductionText, locationDate, initialSalutation, finalSalutation, signature, reportContent }) => {
    const doc = new Document({
      sections: [
        {
          children: [
            // User Address on the left
            userAddress ? new Paragraph({
              children: [new TextRun({ text: userAddress, font: "Arial", size: 24 })],
              alignment: AlignmentType.LEFT,
            }) : null,

            new Paragraph(""),
  
            // Destination Address on the right
            destinationAddress ? new Paragraph({
              children: [new TextRun({ text: destinationAddress, font: "Arial", size: 24 })],
              alignment: AlignmentType.RIGHT,
            }) : null,
  
            new Paragraph(""), // Blank paragraph for spacing
            new Paragraph(""),
            new Paragraph(""),
  
            // Location and Date below the addresses
            locationDate ? new Paragraph({
              children: [new TextRun({ text: locationDate, font: "Arial", size: 24 })],
            }) : null,
  
            new Paragraph(""),
            new Paragraph(""),
  
            // "Concerne:" text in bold
            concerneText ? new Paragraph({
              children: [new TextRun({ text: concerneText, bold: true, font: "Arial", size: 24 })],
            }) : null,
  
            new Paragraph(""),
            new Paragraph(""),
  
            // Initial Salutation
            initialSalutation ? new Paragraph({
              children: [new TextRun({ text: initialSalutation, font: "Arial", size: 24 })],
            }) : null,
  
            new Paragraph(""),
  
            // Introduction Text
            introductionText ? new Paragraph({
              children: [new TextRun({ text: introductionText, font: "Arial", size: 24 })],
            }) : null,
  
            new Paragraph(""),
            new Paragraph(""),
  
            // Report Content, each line as a new paragraph
            ...(reportContent
              ? reportContent.split("\n").map(line => new Paragraph({
                  children: [new TextRun({ text: line, font: "Arial", size: 24 })],
                  alignment: AlignmentType.JUSTIFIED,
                }))
              : []),
  
            new Paragraph(""),
            new Paragraph(""),
            new Paragraph(""),
  
            // Final Salutation
            finalSalutation ? new Paragraph({
              children: [new TextRun({ text: finalSalutation, font: "Arial", size: 24 })],
            }) : null,
  
            new Paragraph(""),
  
            // Signature, aligned to the right
            signature ? new Paragraph({
              children: [new TextRun({ text: signature, font: "Arial", size: 24 })],
              alignment: AlignmentType.RIGHT,
              spacing: { before: 400 },
            }) : null,
          ].filter(Boolean),
        },
      ],
    });
  
    const blob = await Packer.toBlob(doc);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'rapport.docx';
    link.click();
  };