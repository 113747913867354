import React, { useState, useEffect } from 'react';
import Button from './Button';
import {  FaChevronLeft, FaFileWord } from 'react-icons/fa';

const getCurrentDateFormatted = () => {
    const today = new Date();
    const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    const months = [
      'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];
  
    const dayName = days[today.getDay()];
    const day = today.getDate();
    const monthName = months[today.getMonth()];
    const year = today.getFullYear();
  
    return `${dayName} ${day} ${monthName} ${year}`;
  };

const ExportOptions = ({ onExportPDF, onExportWord, onClose, reportContent }) => {
  // Initialise les toggles avec les valeurs de localStorage
  const [includeUserAddress, setIncludeUserAddress] = useState(localStorage.getItem('includeUserAddress') === 'true');
  const [includeDestinationAddress, setIncludeDestinationAddress] = useState(localStorage.getItem('includeDestinationAddress') === 'true');
  const [includeConcerne, setIncludeConcerne] = useState(localStorage.getItem('includeConcerne') === 'true');
  const [includeIntroduction, setIncludeIntroduction] = useState(localStorage.getItem('includeIntroduction') === 'true');
  const [includeLocationDate, setIncludeLocationDate] = useState(localStorage.getItem('includeLocationDate') === 'true');
  const [includeInitialSalutation, setIncludeInitialSalutation] = useState(localStorage.getItem('includeInitialSalutation') === 'true');
  const [includeFinalSalutation, setIncludeFinalSalutation] = useState(localStorage.getItem('includeFinalSalutation') === 'true');
  const [includeSignature, setIncludeSignature] = useState(localStorage.getItem('includeSignature') === 'true');

  const [isMale, setIsMale] = useState(true);

  // État texte initialisé depuis localStorage ou avec valeurs par défaut
  const [userAddress, setUserAddress] = useState(localStorage.getItem('userAddress') || 'Votre adresse');
  const [destinationAddress, setDestinationAddress] = useState("Adresse de destination");
  const [concerneText, setConcerneText] = useState("Concerne: ");
  const [introductionText, setIntroductionText] = useState(localStorage.getItem('introductionText') || `Veuillez trouver ci-joint les informations relatives à ce patient que j'ai vu en consultation.`);
  const [currentDate] = useState(getCurrentDateFormatted());
  const [locationText, setLocationText] = useState(localStorage.getItem('locationText') || "Genève, le");
  const [initialSalutation, setInitialSalutation] = useState(localStorage.getItem(isMale ? 'initialSalutationMale' : 'initialSalutationFemale') || (isMale ? "Monsieur et Cher Confrère," : "Madame et Chère Consœur,"));
  const [finalSalutation, setFinalSalutation] = useState(localStorage.getItem(isMale ? 'finalSalutationMale' : 'finalSalutationFemale') || (isMale ? "Je vous prie d'agréer, Monsieur et Cher Confrère, l'expression de mes salutations distinguées." : "Je vous prie d'agréer, Madame et Chère Consœur, l'expression de mes salutations distinguées."));
  const [signature, setSignature] = useState(localStorage.getItem('signature') || "Signature");


  // Reset function to restore default values
  const resetAllFields = () => {
    setUserAddress('Votre adresse');
    setDestinationAddress('Adresse de destination');
    setConcerneText('Concerne: ');
    setIntroductionText(`Veuillez trouver ci-joint les informations relatives à ce patient que j'ai vu en consultation.`);
    setLocationText("Genève, le");
    setInitialSalutation(isMale ? "Monsieur et Cher Confrère," : "Madame et Chère Consœur,");
    setFinalSalutation(isMale ? "Je vous prie d'agréer, Monsieur et Cher Confrère, l'expression de mes salutations distinguées." : "Je vous prie d'agréer, Madame et Chère Consœur, l'expression de mes salutations distinguées.");
    setSignature("Signature");

    setIncludeUserAddress(true);
    setIncludeDestinationAddress(true);
    setIncludeConcerne(true);
    setIncludeIntroduction(true);
    setIncludeLocationDate(true);
    setIncludeInitialSalutation(true);
    setIncludeFinalSalutation(true);
    setIncludeSignature(true);
  };

  // Effet pour sauvegarder les valeurs des toggles et des textes dans localStorage
  useEffect(() => {
    localStorage.setItem('userAddress', userAddress);
    localStorage.setItem('introductionText', introductionText);
    localStorage.setItem('locationText', locationText);
    localStorage.setItem(isMale ? 'initialSalutationMale' : 'initialSalutationFemale', initialSalutation);
    localStorage.setItem(isMale ? 'finalSalutationMale' : 'finalSalutationFemale', finalSalutation);
    localStorage.setItem('signature', signature);
    localStorage.setItem('includeUserAddress', includeUserAddress);
    localStorage.setItem('includeDestinationAddress', includeDestinationAddress);
    localStorage.setItem('includeConcerne', includeConcerne);
    localStorage.setItem('includeIntroduction', includeIntroduction);
    localStorage.setItem('includeLocationDate', includeLocationDate);
    localStorage.setItem('includeInitialSalutation', includeInitialSalutation);
    localStorage.setItem('includeFinalSalutation', includeFinalSalutation);
    localStorage.setItem('includeSignature', includeSignature);
  }, [userAddress, introductionText, locationText, initialSalutation, finalSalutation, signature, includeUserAddress, includeDestinationAddress, includeConcerne, includeIntroduction, includeLocationDate, includeInitialSalutation, includeFinalSalutation, includeSignature]);

  // Effet pour mettre à jour les salutations en fonction du genre
// Effet pour récupérer et mettre à jour les salutations en fonction du genre
useEffect(() => {
    if (isMale) {
      const savedInitialSalutation = localStorage.getItem('initialSalutationMale');
      const savedFinalSalutation = localStorage.getItem('finalSalutationMale');
      setInitialSalutation(savedInitialSalutation || "Monsieur et Cher Confrère,");
      setFinalSalutation(savedFinalSalutation || "Je vous prie d'agréer, Monsieur et Cher Confrère, l'expression de mes salutations distinguées.");
    } else {
      const savedInitialSalutation = localStorage.getItem('initialSalutationFemale');
      const savedFinalSalutation = localStorage.getItem('finalSalutationFemale');
      setInitialSalutation(savedInitialSalutation || "Madame et Chère Consœur,");
      setFinalSalutation(savedFinalSalutation || "Je vous prie d'agréer, Madame et Chère Consœur, l'expression de mes salutations distinguées.");
    }
  }, [isMale]);

  return (
    <div style={{ maxWidth: '100%', margin: 'auto', padding: '20px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
      <h3 style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>Création du rapport Word
      <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginTop: '30px', marginBottom: '30px' }}>
      <Button onClick={onClose} variant="secondary">
          <FaChevronLeft /> Retour
        </Button>
        <Button 
          onClick={() => onExportWord({ userAddress, destinationAddress, concerneText, introductionText, locationDate: `${locationText} ${currentDate}`, initialSalutation, finalSalutation, signature, reportContent })} variant="word">
          <FaFileWord /> Générer le rapport en Word
        </Button>
      </div>
      </h3>
      {/* Addresses on the same line */}
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold' }}>
            <input type="checkbox" checked={includeUserAddress} onChange={() => setIncludeUserAddress(!includeUserAddress)} style={{ marginRight: '8px' }} />
            Adresse de l'expéditeur
          </label>
          {includeUserAddress && (
            <textarea
              value={userAddress}
              onChange={(e) => setUserAddress(e.target.value)}
              placeholder="Entrez l'adresse de l'expéditeur"
              style={{
                width: '100%',
                height: '60px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px'
              }}
            />
          )}
        </div>

        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold' }}>
            <input type="checkbox" checked={includeDestinationAddress} onChange={() => setIncludeDestinationAddress(!includeDestinationAddress)} style={{ marginRight: '8px' }} />
            Adresse de destination
          </label>
          {includeDestinationAddress && (
            <textarea
              value={destinationAddress}
              onChange={(e) => setDestinationAddress(e.target.value)}
              placeholder="Entrez l'adresse de destination"
              style={{
                width: '100%',
                height: '60px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px'
              }}
            />
          )}
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeConcerne}
            onChange={() => setIncludeConcerne(!includeConcerne)}
            style={{ marginRight: '8px' }}
          />
          Concerne: 
        </label>
        {includeConcerne && (
          <textarea
            value={concerneText}
            onChange={(e) => setConcerneText(e.target.value)}
            placeholder="Entrez le texte de 'Concerne'"
            style={{
                width: '100%',
                height: '35px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px'
              }}
          />
        )}
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeLocationDate}
            onChange={() => setIncludeLocationDate(!includeLocationDate)}
            style={{ marginRight: '8px' }}
          />
          Lieu et la date
        </label>
        {includeLocationDate && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '8px' }}>
            <input
              type="text"
              value={locationText}
              onChange={(e) => setLocationText(e.target.value)}
              placeholder="Lieu"
              style={{
                width: '30%',
                height: '35px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px'
              }}
            />
            <span>{currentDate}</span>
          </div>
        )}
      </div>


      <div style={{ marginBottom: '20px' }}>
  <label style={{ fontWeight: 'bold' }}>
    <input
      type="checkbox"
      checked={includeInitialSalutation}
      onChange={() => setIncludeInitialSalutation(!includeInitialSalutation)}
      style={{ marginRight: '8px' }}
    />
    Salutations initiales de genre
  </label>

  {/* Toggle de genre pour les salutations */}
  <button
    onClick={() => setIsMale(!isMale)}
    style={{
      marginLeft: '10px',
      padding: '8px 16px',
      borderRadius: '20px',
      border: '1px solid #ccc',
      backgroundColor: '#960018',
      color: 'white',
      cursor: 'pointer',
      outline: 'none',
    }}
  >
    {isMale ? 'Masculin' : 'Féminin'}
  </button>

  {includeInitialSalutation && (
    <textarea
      value={initialSalutation}
      onChange={(e) => setInitialSalutation(e.target.value)}
      placeholder="Entrez les salutations initiales"
      style={{
        width: '100%',
        height: '60px',
        marginTop: '8px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontFamily: 'Arial',
        fontSize: '12px'
      }}
    />
  )}
</div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeIntroduction}
            onChange={() => setIncludeIntroduction(!includeIntroduction)}
            style={{ marginRight: '8px' }}
          />
          Introduction
        </label>
        {includeIntroduction && (
          <textarea
            value={introductionText}
            onChange={(e) => setIntroductionText(e.target.value)}
            placeholder="Entrez l'introduction"
            style={{
                width: '100%',
                height: '60px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px'
              }}
          />
        )}
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeFinalSalutation}
            onChange={() => setIncludeFinalSalutation(!includeFinalSalutation)}
            style={{ marginRight: '8px' }}
          />
          Salutations finales
        </label>
        {includeFinalSalutation && (
          <textarea
            value={finalSalutation}
            onChange={(e) => setFinalSalutation(e.target.value)}
            placeholder="Entrez les salutations finales"
            style={{
                width: '100%',
                height: '60px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px'
              }}
          />
        )}
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeSignature}
            onChange={() => setIncludeSignature(!includeSignature)}
            style={{ marginRight: '8px' }}
          />
          Signature
        </label>
        {includeSignature && (
          <textarea
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
            placeholder="Entrez la signature"
            style={{
                width: '100%',
                height: '60px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px'
              }}
          />
        )}
      </div>
      <Button onClick={resetAllFields} variant="danger">
          Rééinitialiser les champs 
        </Button>
    </div>
  );
};

export default ExportOptions;